import React, { useState, useEffect } from 'react';
import { Table, Col, Row, Tag, Button, Form, Input, Select, Modal, Timeline  , Card, notification  } from 'antd';
import TusAccesoriosPeruServices from '../../services/services';
// import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';

let inputRef = null;

const ClientsView = ({ clients = [], refClientsBD, storage, getAllClientsBDFirebase }) => {
  useEffect(() => {
    
  if (inputVisible) {
    inputRef = document.querySelectorAll("#input-category")
    console.log(inputRef)
    if (inputRef && inputRef.length) inputRef[0].focus()
  }
  })
  const [openModalAddClient, setOpenModalAddClient] = useState(false)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setinputValue] = useState(null)
  const [tags, setTags] = useState([])
  const [newClient, setNewClient] = useState({name: "", idDocument: "", phone: "", address: "", mail: "", domainAlias: "", slogan: "", urlLogo: ""})
  const [urlProductAdded, setUrlProductAdded] = useState(null)
  const [clientSelectedUI, setClientSelectedUI] = useState(null)
  const [openModalAddUserToClient, setOpenModalAddUserToClient] = useState(false)
  const [userAdminUI, setuserAdminUI] = useState({userName: "", password: ""})

  const openModalAddClientUI = () => {
    setOpenModalAddClient(true)
  }  
  const closeModalAddClient = () => {
    setOpenModalAddClient(false)
  }
  const onFinishRegister = () => {
    if (newClient) {
      console.log(newClient)
      newClient.idClient = Math.floor(Math.random() * (1000000 - 10000)) + 100;

      // console.log(values)
      registerClient(newClient)
    }
  }
  const registerClient = (client) => {
    const service = new TusAccesoriosPeruServices(refClientsBD);
    if (tags && tags.length > 0) {
      client.configuration = {}
      client.configuration.productsCategories = tags.map((e, index) => {return {label: e, code: index+1}})
    }
    service.saveClientBD(client).then(res => {
      getAllClientsBDFirebase()
      openNotification()
      closeModalAddClient()
      setTags([])
      setNewClient({name: "", idDocument: "", phone: "", address: "", mail: "", domainAlias: "", slogan: "", urlLogo: ""})
    })
  }
  
  const openNotification = () => {
    notification.success({
      message: `USUARIO REGISTRADO`,
      description:
        'El usuario se registro con éxito.',
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const showInput = () => {
    setInputVisible(true)
    
  };
  const handleInputChange = (e) => {
setinputValue(e.target.value)
  }
  const handleInputConfirm = () => {
    let auxTags = [...tags]
    if (inputValue && auxTags.indexOf(inputValue) === -1) {
      auxTags = [...auxTags, inputValue];
    }
    console.log(auxTags);
    setInputVisible(false)
    setinputValue(null)
    setTags(auxTags)
  }

  console.log(tags)
  const handleValueForNewClient = (e, id) => {
    let aux = {...newClient}
    if (id === "urlLogo") {
      if (e && e.target) {
        let file = e.target.files[0]; // get the supplied file
        // if there is a file, set image to that file
        if (file) {
          console.log(file)
          uploadImage(file, aux)
        }
      }
    } else {
      if (e && id) {
        aux[id] = e.target.value
        setNewClient(aux)
      }
    }
    
  }
  if (inputVisible) {
    inputRef = document.querySelectorAll("#input-category")
    console.log( inputRef)
    if (inputRef && inputRef.length)
      inputRef = inputRef[0]
  }
    const uploadImage = (image, obj) => {
      let aux = {...obj}
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
      },
      error => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then(url => {
           console.log(url)
           setUrlProductAdded(url)
           if (url) {             
           aux.urlLogo = url
           setNewClient(aux)
           }
          });
      }
    );

  }
  const handleClient = (e) => {
    setClientSelectedUI(e)
    console.log(e)
    setOpenModalAddUserToClient(true)
  }
  const closeAssigneUser = () => {
    setOpenModalAddUserToClient(false)
  }
  const onFinishAssignUser = () => {
    let aux = {...clientSelectedUI}
    console.log(userAdminUI)
    // aux.configuration.userAdmin = {}
    // if (aux && aux.configuration && userAdminUI) {
    //   aux.configuration.userAdmin.userName = userAdminUI.userName
    //   aux.configuration.userAdmin.password = userAdminUI.password
      
      console.log(aux)
      userAdminUI.codeAuthorized = aux.idClient
      userAdminUI.token = aux.keyBD.replace("IDCLIENT","")
      console.log(userAdminUI)
      if (aux && aux.keyBD) {
        const service = new TusAccesoriosPeruServices(refClientsBD)

        service.saveNewUserBD(userAdminUI).then(res => {
          closeAssigneUser()
          setuserAdminUI({userName: "", password: "", codeAuthorized: null, token: ""})
        })
      }
    // }
  }
  const onFinishFailedAssign = (error) => {
    console.log(error)
  }
  const handleuserAdmin = (e, id) => {
    let aux = {...userAdminUI}
    if (e && id) {
      aux[id] = e.target.value
      setuserAdminUI(aux)
    }
    

  }
  return (
    <div className="main-cients">
      <Button type="dashed" onClick={() => openModalAddClientUI()} >NUEVO CLIENTE</Button>
    <table id="table-clients">
        <thead>
          <tr>
            <td>CODIGO</td>
            <td>NOMBRES</td>
            <td>DNO o RUC</td>
            <td>TELEFONO</td>
            <td>DIRECCION</td>
          </tr>
        </thead>
          <tbody>
            {
              clients && clients.map(e => {
                return (
                  <tr style={{cursor: "pointer"}} onClick={() => handleClient(e)}>
                    <td>{e.idClient}</td>
                    <td>{e.name}</td>
                    <td>{e.idDocument}</td>
                    <td>{e.phone}</td>
                    <td>{e.address}</td>
                  </tr>
                )
              })
            }
        </tbody>
    </table>
    <Modal visible={openModalAddUserToClient} onCancel={() => closeAssigneUser()} width={320} cancelText="CERRAR" closable={false} title="Información del cliente"
                okButtonProps={{hidden: true}} cancelButtonProps={{hidden: false}} style={{ top: 20 }} className="modal-select-sale"
      >
        {clientSelectedUI && 
        <Timeline>
        <Timeline.Item>
            <img style={{maxWidth:"150px"}} src={(clientSelectedUI && clientSelectedUI.urlLogo) && clientSelectedUI.urlLogo} className="img-responsive" />
        </Timeline.Item>
        <Timeline.Item><b>Código de cliente:</b> {clientSelectedUI.idClient}</Timeline.Item>
          <Timeline.Item><b>Nombre o razón social</b> {clientSelectedUI.name}</Timeline.Item>
          <Timeline.Item><b>Eslogan:</b> {clientSelectedUI.slogan}</Timeline.Item>
          <Timeline.Item><b>Número de documento:</b> {clientSelectedUI.idDocument}</Timeline.Item>
          <Timeline.Item><b>Correo:</b> {clientSelectedUI.mail}</Timeline.Item>
          <Timeline.Item><b>Teléfono:</b> {clientSelectedUI.phone}</Timeline.Item>
          <Timeline.Item><b>Alias:</b> {clientSelectedUI.domainAlias}</Timeline.Item>
          <Timeline.Item><b>Dirección:</b> {clientSelectedUI.address}</Timeline.Item>
        </Timeline>
        }
        {
          !(clientSelectedUI && clientSelectedUI.configuration && clientSelectedUI.configuration.userAdmin) ?
        <>
        <p><b>Asigna un usuario y clave:</b></p>
        <form>
         <div style={{display: "inline-grid"}}>
           <label>Usuario:</label>
          <input value={userAdminUI.userName} id="userName" onChange={(e) => handleuserAdmin(e, "userName")} />
        </div>
        <div style={{display: "inline-grid"}}>
           <label>Contraseña:</label>
          <input value={userAdminUI.password} id="password" type="password" onChange={(e) => handleuserAdmin(e, "password")} />
        </div>
        <br />
        <br />
        <div>
          <Button type="button" onClick={onFinishAssignUser} danger>
            ASIGNAR
          </Button>
        </div>
      </form>
      </>
      :
      <p><b>Este comercio ya tiene asignado el usuario:</b> {clientSelectedUI.configuration.userAdmin.userName}</p>

        }
        </Modal>
    <Modal visible={openModalAddClient} onCancel={() => closeModalAddClient()} width={450} cancelText="CERRAR"
                okButtonProps={{hidden: true}} cancelButtonProps={{hidden: false}} style={{ top: 20 }} className="modal-select-sale"
      >
         <form
      // {...layout}
      // name="basic"
      // initialValues={{
      //   remember: true,
      // }}
      // onFinish={onFinishRegister}
      // onFinishFailed={onFinishFailed}
    >
      <br/>
          <div style={{display: "grid"}}>
            <label>Nombres o razón social:</label>
            <Input value={newClient.name} onChange={(e) => handleValueForNewClient(e, "name")} style={{ width: 280 }} />
          </div>
          <div style={{display: "grid"}}>
            <label>DNI o RUC</label>
            <Input value={newClient.idDocument} onChange={(e) => handleValueForNewClient(e, "idDocument")} style={{ width: 120 }} />
          </div>
          <div style={{display: "grid"}}>
            <label>Teléfono:</label>
            <Input value={newClient.phone} onChange={(e) => handleValueForNewClient(e, "phone")} style={{ width: 120 }} />
          </div>
          <div style={{display: "grid"}}>
            <label>Dirección:</label>
            <Input value={newClient.address} onChange={(e) => handleValueForNewClient(e, "address")} style={{ width: 280 }} />
          </div>
          <div style={{display: "grid"}}>
            <label>Correo electrónico:</label>
            <Input value={newClient.mail} onChange={(e) => handleValueForNewClient(e, "mail")} style={{ width: 280 }} />
          </div>
          <div style={{display: "grid"}}>
            <label>Alias:</label>
            <Input value={newClient.domainAlias} onChange={(e) => handleValueForNewClient(e, "domainAlias")} style={{ width: 120 }} />
          </div>
          <div style={{display: "grid"}}>
            <label>Eslogan:</label>
            <Input value={newClient.slogan} onChange={(e) => handleValueForNewClient(e, "slogan")} style={{ width: 280 }} />
          </div>
          <div style={{display: "grid"}}>
            <label>Subir logo:</label>
            <Input type="file" onChange={(e) => handleValueForNewClient(e, "urlLogo")} style={{ width: 280 }} accept="image/x-png,image/jpeg"  />
            {
            urlProductAdded &&
            <img src={urlProductAdded} className="product-loaded"/>
          }
          </div>
          <br />
          <div>
            <h5>(*) Opcionalmente puedes agregar las categorías de productos del comercio en este momento."</h5>
            {tags && tags.map(e =>  <Tag color="orange">{e}</Tag>)}
                  {inputVisible && (
                    <Input
                      // ref={saveInputRef}
                      type="text"
                      id="input-category"
                      size="small"
                      style={{ width: 78 }}
                      value={inputValue}
                      onChange={(e) => handleInputChange(e)}
                      onBlur={() => handleInputConfirm()}
                      onPressEnter={() => handleInputConfirm()}
                    />
                  )}
                  {!inputVisible && (
                    <Tag onClick={showInput} className="site-tag-plus">
                      <PlusOutlined /> Categoría
                    </Tag>
                  )}
            </div>
            <br />
          <div {...tailLayout}>
        <Button type="button" onClick={() => onFinishRegister()} danger
        disabled={!(newClient && newClient.name && newClient.idDocument && newClient.phone && newClient.address && newClient.mail && newClient.domainAlias)}
        >
          REGISTRAR
        </Button>
      </div>
      </form>     
        </Modal>
</div>
  )
}

export default ClientsView;
