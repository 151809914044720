import React, { useState, useEffect } from 'react';
import { Tooltip, Button, Modal, Input, Form, Alert  } from 'antd';
import { useHistory } from 'react-router-dom';
// import ModalComponent from '../../components/shared/modal';
import ContentAllProducts from '../../shared/modal/content-modal';
import { urlWhatsApp } from '../../../constants/routes';
import TusAccesoriosPeruServices from '../../../services/services';
import {USER_ADMIN} from "../../../constants/constants"
// import WishListModalComponent from '../shared/modal/wishlist-modal';
// import { BlackRampsProducts } from '../../../constants/constants'
import MenuComponent from '../../shared/menu';
import WishListModalComponent from '../../shared/modal/wishlist-modal';
import MainModalComponent from '../../shared/modal/main-modal';
import Footer from '../../shared/footer';
// import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
// import IntroModalComponent from '../shared/modal/intro';
const ID_TINO = 668764

let urlActive = window.location.href
urlActive = urlActive.split(".")

console.log(urlActive)
urlActive = urlActive[0]
if (urlActive) urlActive = urlActive.replace("http://", "").replace("https://", "")
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');

const MainStoreComponent = ({ reference, refDashboarClients, propsAux, refUsers, refDashboardClientsBD }) => {
  useEffect(() => {
      if (categorySelectedForUser) filterProductsByCategory(categorySelectedForUser)
      if (allUsersAvailables && allUsersAvailables.length && userInfoLogin) validateUser()
    setTimeout(() => {
        // setProductsInitial(BlackRampsProducts)
        // console.log(openIntroModal)
        // if (openIntroModal === false) setOpenIntroModal(true)
    }, 1200)
    setTimeout(() => {
        console.log(openIntroModal)
if (!openIntroModal) setOpenIntroModal(true)
        // if (openIntroModal === false) setOpenIntroModal(true)
    }, 200)
})
const validateUser = () => {
    console.log("validar usuario")
    console.log(allUsersAvailables)
    allUsersAvailables.forEach(e => {
        if (e && e.userName === userInfoLogin.username && e.pass === userInfoLogin.password && propsAux && propsAux.history) {
            console.log("usuario encontrado", e)
            localStorage.setItem("token", e.token)
            propsAux.history.push(`/administrador/${e.codeAuthorized}`)
           return setUserInvalid(false)            
        } else setUserInvalid(true)
    })
}
const layoutLogin = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayoutLogin = {
    wrapperCol: { offset: 8, span: 16 },
  };
const finalOrderObj = {
    fullNames:'',
    dni: '',
    phone: '',
    address: '',
    quantity: 1,
    comment: ''
}
const productSelectedInitial = {
comment: '',
quantity: 1
}
const [typePaySelectedWishList, settypePaySelectedWishList] = useState(null)
const [listAllClientsBD, setListAllClientsBD] = useState([])
const [openModalUI, setOpenModalUI] = useState(false)
const [quantitySelected, seQquantitySelected] = useState(1)
const [responseSentEmail, setResponseSentEmail] = useState(null)
const [productSelected, setProductSelected] = useState(productSelectedInitial)
const [finalOrder, setFinalOrder] = useState(finalOrderObj)
const [productsInitial, setProductsInitial] = useState([])

const [loadInfoById, setLoadInfoById] = useState({})
const [openModalForCategoryState, setOpenModalForCategoryState] = useState(false)
const [amoutForDelivery, setAmoutForDelivery] = useState(null)
const [modalLoginActive, setModalLoginActive] = useState(false)
const [userInvalid, setUserInvalid] = useState(false)

const [wishList, setWishList] = useState([])
const [openModalWishListUI, setOpenModalWishList] = useState(false)
const [payNowUI, setPayNowUI] = useState(false)
const [quantityWishList, setQuantityWishList] = useState(1)
const [responseSentOrderWishList, setResponseSentOrderWishList] = useState(null)
const [typePaYSelected, setTypePaYSelected] = useState(null)
const [amoutForDeliveryWishList, setAmoutForDeliveryWishList] = useState(null)
const [yapeModal, setOpenYapeModal] = useState(false)
const [openIntroModal, setOpenIntroModal] = useState(false)
const [categorySelectedForUser, setCategorySelectedForUser] = useState(null)
const [allUsersAvailables, setAllUsersAvailables] = useState(null)
const [userInfoLogin, setUserInfoLogin] = useState(null)

document.title = (loadInfoById && loadInfoById.name) || ""
document.querySelector('meta[name="description"]').setAttribute("content", loadInfoById && loadInfoById.slogan || "");

console.log(openIntroModal)
const filterProductsByCategory = (categorySelected = "") => {
    if (categorySelected === null) getAllClientsBDFirebase()
    let aux = {...loadInfoById}
    let result = []
    if (aux && aux.products)
        aux.products.forEach(e => { 
            if(e && e.category === categorySelectedForUser.code) result.push(e)
         })
         console.log(result)
         aux.products = result
         if (result) setLoadInfoById(aux)
         setCategorySelectedForUser(null)
}
const closeModalLogin = () => {
    setModalLoginActive(false)
}
const handleLogin = () => {
    setModalLoginActive(true)
}
const getAllClientsBDFirebase = () => {    

    let clientsAux = []
    refDashboarClients.on("value", (snapshot) => {
         if (snapshot.val() !== null) {
           clientsAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           clientsAux = Object.values(clientsAux)
           if (urlActive) filterById(urlActive, clientsAux)          
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
}

const getAllUsersBDFirebase = () => {
    console.log("users")
    let usersAux = []
    refUsers.on("value", (snapshot) => {
        console.log(snapshot.val())
        if (snapshot.val() !== null) {
          usersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
          usersAux = Object.values(usersAux)
          console.log(usersAux) 
          setAllUsersAvailables(usersAux)
        }
        return;
      }, (error) => {
        console.log("ERROR: " + error.code);
      });
}

const filterById = (id, clientsAux) => {
    let result = []
    console.log(id)
    setListAllClientsBD(clientsAux)
    console.log(clientsAux, "clientsAux")
    if (clientsAux) {
        clientsAux.forEach(e => {
            if (e["domainAlias"] === id) {
                let aux = {...e}
                aux.products = aux.products && aux.products.map(k => {
                    k.checked = false
                    return k
                })
                console.log(e)
                setLoadInfoById(e)
            }
        })
    }
}

if (listAllClientsBD.length === 0) {
    getAllClientsBDFirebase()
}
const openModal = (el) => {
console.log('open modal', el)
setProductSelected(el)
setOpenModalUI(true)
}
const openModalWishList = (el) => {
console.log('open modal', el)
//   setProductSelected(el)
setOpenModalWishList(true)
}
const closeModalIntroUI = (el) => {
    console.log('close modal', el)
    //   setProductSelected(el)
    setOpenIntroModal(false)
    }
const closeModalWishList = () => {
console.log('close modal wishlist')
console.log(responseSentOrderWishList)
setOpenModalWishList(false)
setPayNowUI(false)
if (responseSentOrderWishList && responseSentOrderWishList.data) {
    // handleReturnOption(1)
    setWishList([])
    setProductsInitial([])
    setResponseSentOrderWishList(null)
}
}
const closeModal = () => {
const listInputs = [
    'basic_fullNames',
    'basic_dni',
    'basic_phones',
    'basic_username',
    'basic_quantityItems',
    'basic_clientComment',
]

listInputs.forEach((el) => {
    const aux = document.querySelectorAll(`#${el}`)
    if (aux && aux[0] && aux[0].id === 'basic_quantityItems') {
        console.log(aux[0])
        aux[0].value = 1
    }
    if (aux && aux[0]) {
        console.log(aux[0])
        aux[0].value = ''
    }
})
console.log('close modal')
setOpenModalUI(false)

setFinalOrder(finalOrderObj)
}
const handleAmountForPay = (e) => {
    if (e) setAmoutForDelivery(e)

}
const onFinish = (values) => {
    let auxProductSelected = {...productSelected}
    console.log(reference)
    const service = new TusAccesoriosPeruServices(reference);

    if (values && !values.quantityItems) {
        values.quantityItems = 1
    }
    setFinalOrder(values)
    console.log(values)
    console.log(productSelected)
    if (productSelected && productSelected.name) {
        values.productSelected = productSelected.name
        
        // values.storeCode = '02'
    }
    if (values && values.payType === 5 && !values.amoutForDelivery && amoutForDelivery) values.amoutForDelivery = amoutForDelivery
    if (values && loadInfoById) {
        values.infoByStore = {}
        values.infoByStore.idByStore = {}
        values.infoByStore.idByStore.key = loadInfoById.keyBD
        values.infoByStore.idByStore.id = loadInfoById.idClient
   
    values.idSale = Math.floor(Math.random() * (1000000 - 10000)) + 100
    values.totalAmount = productSelected.price * values.quantityItems
    values.state = 1
    values.datesInformation = {}
    values.datesInformation.dateRegisteredToBuy = moment.tz(new Date(), "America/Lima").format()
    console.log('Success:', values);
    values.soldUnits = productSelected.soldUnits + values.quantityItems
    console.log(values)
    console.log(listAllClientsBD)
    let resultClient = {}
    resultClient = getUserForSale(values.infoByStore.idByStore.id, listAllClientsBD)
    console.log(resultClient)
    auxProductSelected.soldUnits = auxProductSelected.soldUnits + values.quantityItems
    resultClient.products = resultClient.products.map(e => {
        if (e.cod === auxProductSelected.cod) e = auxProductSelected
        return e
    })
    console.log(resultClient.products)
    service.saveSaleBD(values).then(res => {
        updateStockFromWeb(resultClient)
        setResponseSentEmail({
            data: {
                status: 200,
                whatsAppMsg: urlWhatsApp(values)
            }
        })
    })
    }
};
const getUserForSale = (id, list) => {
    console.log(id)
    let result = {}
    if (list) {
        list.forEach(e => {
            if (e && e.idClient === id) result = e
        })
    }

    return result;
}
const updateStockFromWeb = (client) => {
    const service = new TusAccesoriosPeruServices(refDashboardClientsBD);
    service.updateStockDBfromWeb(client.keyBD, client)
    
  }
const onFinishWishList = (values) => {
    let auxWishList = [...wishList]
    console.log(reference)
    const service = new TusAccesoriosPeruServices(reference);

    setFinalOrder(values)
    let aux = { ...values }
    console.log(values)
    console.log(wishList)
    aux.products = []
    console.log(loadInfoById.products)
    let auxProductsLoadedInitial = [...loadInfoById.products]
    // auxWishList = auxWishList && auxWishList.map((e, index) => {
    //     if (loadInfoById.products) {
    //         e.soldUnits = loadInfoById.products[index] && loadInfoById.products[index].soldUnits
    //     }
    //     return e
    //     // loadInfoById.products.forEach(k => {
    //     //     if (e.cod === k.cod) e.soldUnits = k.soldUnits
    //     //     console.log(e)
    //     // })
    // })
    // console.log(auxWishList)
    aux.products = wishList
    console.log(aux)
    aux.amoutForDeliveryWishList = amoutForDeliveryWishList
    // aux.storeCode = '02'

    let obj = {
        address: aux.addressWishList,
        addressReference: aux.addressReference,
        amountForDelivery: aux.amoutForDeliveryWishList || null,
        fullNames: aux.fullNamesWishList,
        idSale: Math.floor(Math.random() * (1000000 - 10000)) + 100,
        infoByStore: {},
        payType: aux.payType,
        phones: aux.phonesWishList,
        productSelected: aux.products,
        quantityItems: aux.products.length,
        state: 1,
        totalAmount: calculateTotalAmountWishList(aux.products)
    }
    if (obj && loadInfoById) {
        obj.infoByStore = {}
        obj.infoByStore.idByStore = {}
        obj.infoByStore.idByStore.key = loadInfoById.keyBD
        obj.infoByStore.idByStore.id = loadInfoById.idClient
    console.log(obj)

    obj.datesInformation = {}
    obj.datesInformation.dateRegisteredToBuy = moment.tz(new Date(), "America/Lima").format()
    // values.soldUnits = productSelected.soldUnits + values.quantity
    console.log(wishList)
    let resultClient = {}
    resultClient = getUserForSale(obj.infoByStore.idByStore.id, listAllClientsBD)

    console.log(resultClient)
    resultClient.products = []
    auxWishList = auxWishList && auxWishList.map(e => {
        e.soldUnits = e.quantity + e.soldUnits
        if (e.checked) delete e.checked
        return e;
    })
    console.log(auxWishList)
    console.log(auxProductsLoadedInitial)
    // let resultWithExistens = []
    // auxProductsLoadedInitial.forEach()
    if (auxProductsLoadedInitial && auxWishList && auxProductsLoadedInitial.length > auxWishList.length) {
        auxWishList.forEach((e, index) => {
                auxProductsLoadedInitial.forEach(k =>{
                    // if (auxWishList[index] && auxWishList[index].cod) {
            if (auxWishList.length <= auxProductsLoadedInitial.length) {
                if (k && k.cod) {
                    if (e.cod !== k.cod) {
                        console.log(k)
                        auxWishList.push(k)
                    }

                }
                    // }
            }
                })
        })
        
    }

    if (wishList && wishList.length > 1) {          
          let filteredArr = auxWishList.reduce((acc, current) => {
            const x = acc.find(item => item.cod === current.cod);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          console.log(filteredArr)

          if (filteredArr && filteredArr.length > 0) {
              auxWishList = []
              auxWishList = filteredArr
          }
    }
    console.log(auxProductsLoadedInitial)
    console.log(auxWishList)
    resultClient.products = auxWishList;
    console.log(resultClient)
    service.saveSaleBD(obj).then(res => {
        updateStockFromWeb(resultClient)
        setWishList([])
        getAllClientsBDFirebase()
        setResponseSentOrderWishList({
            data: {
                status: 200,
                whatsAppMsg: urlWhatsApp(values)
            }
        })
    })
    }

}
const calculateTotalAmountWishList = (products) => {
    let total = []
    let totalAmount = 0
    products && products.forEach(e => {
      const obj = e.quantity * e.price;
      total.push(obj)
    });
  
    console.log(total)
    totalAmount = (total && total.length > 0) && total.reduce((a,b) => a + b)
    console.log(totalAmount)
    return totalAmount
}
const onFinishFailed = (errorInfo) => {
console.log('Failed:', errorInfo);
};
const layout = {
labelCol: { span: 8 },
wrapperCol: { span: 16 },
};
const tailLayout = {
wrapperCol: { offset: 8, span: 16 },
};
const payNow = () => {
setPayNowUI(true)
}
const payFinishOrder = () => {

}
const backToShop = () => {
    closeModal()
    setResponseSentEmail(null)
}
const handleReturnOption = (option) => {
    if (option === 1) {
        setResponseSentOrderWishList(null)
        let aux = [ ...wishList ]
        aux = aux.map(e => {
            if (e.checked) e.checked = false
            return e;
        })
        setWishList(aux)
        setProductsInitial([])
        setWishList([])
    }
    
        closeModalWishList()
        let aux = [ ...wishList ]
        aux = aux.map(e => {
            if (e.checked) e.checked = false
            return e;
        })
        setWishList(aux)
        setProductsInitial([])
        setWishList([])

}
const backToShopFromWishList = () => {
    let auxLoadInfoById = {...loadInfoById}
    let result = []
    setOpenModalWishList(false)
    setResponseSentOrderWishList(null)
    let aux = [ ...wishList ]
    aux = aux.map(e => {
        if (e.checked) e.checked = false
        return e;
    })
    auxLoadInfoById.products.forEach(e => {
        e.checked = false
        result.push(e)
    })
    if (result) auxLoadInfoById.products = result
    console.log(auxLoadInfoById)
    setLoadInfoById(auxLoadInfoById)
    setPayNowUI(false)
    setWishList(aux)
    setProductsInitial([])
    setWishList([])
    console.log(wishList)
}
const onChangeQuantity = (e, productSelected) => {
    let aux = [ ...wishList]
    if (e) {
        console.log(e)
        console.log(productSelected)
        console.log(wishList)
        if (wishList) {
            aux = aux.map(el => {
                if (productSelected.cod === el.cod) {
                    console.log(el)
                    el.quantity = e
                    el.soldUnits = productSelected.soldUnits
                }
                return el
            })
        }
        console.log(aux)
        setWishList(aux)
    }
}
const setQuantity = (evt) => {
    if (evt) {
        console.log(evt)
        seQquantitySelected(evt)
    }
}
const handleProduct = (e) => {
    let auxUserActive = {...loadInfoById}
    console.log(e)
    let auxList = [...wishList]
    let filterArray = []
    const auxProduct = { ...e.target.value}
    console.log(e.target.checked)
    console.log(e.target.value)
    if (e) {
        if (!e.target.checked) {
            console.log("entro")
            auxList = auxList.map((el, index) => {
                if (el.cod === e.target.value.cod) {
                    el.checked = false
                    el = {}                        
                    if ((el) && el.quantity) el.quantity = 1
                }
                return el
            })
            auxUserActive.products = auxUserActive.products && auxUserActive.products.map(j => {
                if (j.cod === auxProduct.cod) j.checked = e.target.checked
                return j
            })
        
            setLoadInfoById(auxUserActive)
        } else {
            console.log("agregar lista")
            auxProduct.checked = e.target.checked
            auxProduct.quantity = 1
            console.log(auxProduct)
            auxUserActive.products = auxUserActive.products && auxUserActive.products.map(j => {
                if (j.cod === auxProduct.cod) j.checked = e.target.checked
                return j
            })
        
            setLoadInfoById(auxUserActive)
            auxList.push(auxProduct)
        }
        console.log(auxList)
        auxList.forEach(e => {if (e && e.cod) filterArray.push(e)})
        console.log(filterArray)
        setWishList(filterArray)
    }
}
const openYapeModal = () => {
    setOpenYapeModal(true)
}
const closeYapeModal = () => {
    setOpenYapeModal(false)
}
const onFinishLogin = (values) => {
    console.log('Success:', values);
    getAllUsersBDFirebase()
    setUserInfoLogin(values)
    // if (values) {
    //     if (values.username === USER_ADMIN.username && values.password === USER_ADMIN.password && propsAux && propsAux.history) 
    //             propsAux.history.push(`/administrador/${loadInfoById.idClient}`)        
    // } else alert("USUARIO INVALIDO")
  };

  const onFinishFailedLogin = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
console.log(wishList, "wishList")
const handleCategory = () => {}
console.log(productsInitial)
console.log(listAllClientsBD)
console.log(loadInfoById)
const selectCategoryFn = (e) => {
    getAllClientsBDFirebase()
    console.log(e)
    setCategorySelectedForUser(e)
    setOpenModalUI(false)
    closeModalCategories()
}
const closeModalCategories = () => {
    setOpenModalForCategoryState(false)
  };
  
  const openModalForCategory = () => {
    setOpenModalForCategoryState(true)
  };
  const handleTypePay = (e) => {
    console.log(e)
    setTypePaYSelected(e)
  }
  console.log(wishList)
  const handleTypePayWishList = (e) => {
      console.log(e)
    settypePaySelectedWishList(e)
  }
  const handleAmountForPayWishList= (e) => {
    setAmoutForDeliveryWishList(e)
  }
  console.log(loadInfoById)
    return (
        
        <div className="App blackramps">
            {/* <Alert message="Espacio dedicado a Blackramps, partner de TUSACCESORIOS PERU" type="info" closable={false} onClick={() => window.location.href="/"} /> */}
        <a href={`tel:+51${loadInfoById.phone || ""}`} target="_blank" className="call-img">
            <img src="./images/logo-call.png" />
        </a>
            <a href={`https://wa.me/51${loadInfoById.phone || ""}`} target="_blank" className="whats-img">
            <Tooltip placement="left" title={<span>En qué podemos ayudarte?</span>}>
                <img src="./images/logo-whats.png" />
        </Tooltip>
            </a>
        <div className="block">
                    <div className="container">
                        <div className="document gopro">
                        <div className="document__header">
                                <MenuComponent
                                handleLogin={handleLogin}
                                openModalForCategory={openModalForCategory} openModalState={openModalForCategoryState} closeModal={closeModalCategories} selectCategoryFn={selectCategoryFn} onClick={handleCategory} optionActive="" menuList={(loadInfoById && loadInfoById.configuration && loadInfoById.configuration.productsCategories) && loadInfoById.configuration.productsCategories} />
                                <img src={(loadInfoById && loadInfoById.urlLogo) && loadInfoById.urlLogo} className="img-responsive" />
                                <h1 className="document__title">{loadInfoById.name || ""}</h1>
                                {(loadInfoById && loadInfoById.slogan) && <h2 className="document__subtitle">{loadInfoById.slogan}</h2>}
                                {categorySelectedForUser && <h2>Estas visualizando todos los productos de la categoría {categorySelectedForUser.label.toUpperCase()}</h2>}
                            </div>
                            <div className="document__content card">
                                <div className="typography">
                                    <ContentAllProducts 
                                    menuList={(loadInfoById && loadInfoById.configuration && loadInfoById.configuration.productsCategories) && loadInfoById.configuration.productsCategories}
                                    openModal={openModal} onChange={handleProduct} products={(loadInfoById && loadInfoById.products) ? loadInfoById.products : []} positionShop="02" />

                                    {/* <div className="document__signature">
                                        <AppImage src="/images/signature.jpg" width="160" height="55" />
                                    </div> */}
                                </div>
                            </div>
                        <Footer openYapeModal={openYapeModal} email={(loadInfoById && loadInfoById.mail) && loadInfoById.mail || ""}/>
                        </div>

                        </div>
                        </div>
                        <Button type="primary" onClick={openModalWishList} danger id="btn-wishlist" className={(wishList.length > 0 ) ? "active" : "desactive"}>
                            MI LISTA DE DESEO
                            </Button>
                <MainModalComponent
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    finalOrder={finalOrder}
                    visible={openModalUI}
                    closeModal={closeModal}
                    productSelected={productSelected}
                    layout={layout}
                    tailLayout={tailLayout}
                    responseSentEmail={responseSentEmail}
                    backToShop={backToShop}
                    setQuantity={setQuantity}
                    quantitySelected={quantitySelected}
                    handleTypePay={handleTypePay}
                    handleAmountForPay={handleAmountForPay}
                    amoutForDelivery={amoutForDelivery}
                    typePaYSelected={typePaYSelected}
                    storeActive={loadInfoById}
                    phoneWsp={(loadInfoById && loadInfoById.phone)}
                />

                <WishListModalComponent 
                phoneWsp={(loadInfoById && loadInfoById.phone)}
                visible={openModalWishListUI}
                closeModal={closeModalWishList}
                products={wishList}
                payNow={payNow}
                payNowUI={payNowUI}
                payFinishOrder={payFinishOrder}
                onFinishWishList={onFinishWishList}
                onChangeQuantity={onChangeQuantity}
                quantityWishList={quantityWishList}
                responseSentOrderWishList={responseSentOrderWishList}
                backToShopFromWishList={backToShopFromWishList}
                typePaySelectedWishList={typePaySelectedWishList}
                handleTypePayWishList={handleTypePayWishList}
                handleAmountForPayWishList={handleAmountForPayWishList}
                amoutForDeliveryWishList={amoutForDeliveryWishList}
                />

                <Modal visible={modalLoginActive} onCancel={() => closeModalLogin()} width={450} cancelText="CERRAR" style={{ top: 20 }}
                    okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: true}}
                >
                    <div id="modal-login">
                    <Form
                        {...layoutLogin}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinishLogin}
                        onFinishFailed={onFinishFailedLogin}
                        >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input width="150px" />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        {userInvalid &&  <Alert message="Usuario inválido" type="error" showIcon />}
                        <br />
                        <Form.Item {...tailLayoutLogin}>
                            <Button type="primary" htmlType="ENTRAR">
                            INGRESAR
                            </Button>
                        </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </div>
    )
}

export default MainStoreComponent;
