import React, { useState } from 'react';
import Dashboard from './components/dashboard';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Tooltip, Button } from 'antd';import './App.css';
 import firebase from 'firebase';
 import config from './config';
import MainStoreComponent from './components/clients/tino';
import DashboardClients from './components/dashboard-clients';
import InfoPageComponent from './components/info-page';


firebase.initializeApp(config);
const publicationRef = firebase.database();
const publicationRefStorage = firebase.storage()
const ref =	publicationRef.ref('/');
const refDashboardProducts = publicationRef.ref('/products');
const refDashboardClientsBD = publicationRef.ref('/');
const refDashboardSales = publicationRef.ref("/salesBD");
const refDashboarClients = publicationRef.ref('/clientsBD');
const refProviders = publicationRef.ref("/");
const refProvidersBD = publicationRef.ref("/providersBD");
const refUsers = publicationRef.ref("/usersBD");

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  
 render() {
   
let urlActiveAux = window.location.href
urlActiveAux = urlActiveAux.replace("buhope.com","")
urlActiveAux = urlActiveAux.split(".")

console.log(urlActiveAux)
if (urlActiveAux && urlActiveAux.length === 1) return window.location.replace("https://info.buhope.com")
  return (
    <BrowserRouter>
    <div>
        <Switch>
          {/* <Route path="/dashboard">
            <Dashboard 
            refDashboardProducts={refDashboardProducts} 
            refClientsBD={refDashboardClientsBD}
            refDashboardSales={refDashboardSales} refDashboarClients={refDashboarClients} 
            refProviders={refProviders}
            refProvidersBD={refProvidersBD}
            publicationRef={publicationRef}
            storage={publicationRefStorage}
            propsAux={props}
            />
          </Route> */}
          <Route path="/" render={(props) => 
            // <ShopComponent reference={ref} propsAux={props} refDashboardProducts={refDashboardProducts} refClientsBD={refDashboardClientsBD} />
            <MainStoreComponent reference={ref} refDashboardClientsBD={refDashboardClientsBD} refDashboarClients={refDashboarClients} refUsers={refUsers}
            propsAux={props} />
            } />
          <Route path="/main-dashboard" render={(props) => 
            <Dashboard
              refDashboardProducts={refDashboardProducts} 
              refClientsBD={refDashboardClientsBD}
              refDashboardSales={refDashboardSales} refDashboarClients={refDashboarClients} 
              refProviders={refProviders}
              refProvidersBD={refProvidersBD}
              publicationRef={publicationRef}
              storage={publicationRefStorage}
              propsAux={props}
            />}
            />
            <Route path="/administrador/:id" render={(props) => 
              <DashboardClients
                refDashboardProducts={refDashboardProducts} 
                refClientsBD={refDashboardClientsBD}
                refDashboardSales={refDashboardSales} 
                refDashboarClients={refDashboarClients} 
                refProviders={refProviders}
                refProvidersBD={refProvidersBD}
                publicationRef={publicationRef}
                storage={publicationRefStorage}
                propsAux={props}
              />}
              />
              <Route path="/info" render={(props) => 
             // <ShopComponent reference={ref} propsAux={props} refDashboardProducts={refDashboardProducts} refClientsBD={refDashboardClientsBD} />
             <InfoPageComponent reference={ref} refDashboardClientsBD={refDashboardClientsBD} refDashboarClients={refDashboarClients} refUsers={refUsers}
             propsAux={props} />
             } />
        </Switch>
    </div>    
    
    </BrowserRouter>
  )
 }
}

export default App;
