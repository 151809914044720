import React, { useState } from 'react';
import { Menu, Button, Modal, Card } from 'antd';

const { SubMenu } = Menu;
const gridStyle = {
  width: '33%',
  textAlign: 'center',
  cursor: "pointer"
};

const MenuComponent = ({ openModalForCategory, openModalState, optionActive = null, handleLogin = () => {}, menuList, selectCategoryFn = () => {}, closeModal = () => {} }) => {
  console.log(menuList)
  const [theme, setTheme] = useState('dark')
  const [current, setCurrent] = useState('1')

  const changeTheme = (value) => {
    setTheme(value ? 'dark' : 'light')
    // this.setState({
    //   theme: value ? 'dark' : 'light',
    // });
  };

 
  return (
    <div>
        <Button danger id ="btn-login" onClick={() => handleLogin()}>LOGIN</Button>
        <Button id ="menu-content" onClick={() => openModalForCategory()}>VER CATEGORIAS</Button>
            <Modal visible={openModalState} onCancel={() => closeModal()} width={550} cancelText="CERRAR"
                okButtonProps={{hidden: true}} cancelButtonProps={{hidden: false}} centered className="modal-select-category" closable={false}
      >
        <Card>
        {
          menuList && menuList.map((e, index) => <Card.Grid onClick={() => selectCategoryFn(e)} style={gridStyle}>{e.label.toUpperCase()}</Card.Grid>)
        }
        <Card.Grid onClick={() => selectCategoryFn(null)} style={gridStyle}>VER TODOS</Card.Grid>
        </Card>
      </Modal>
      </div>
  )
}

export default MenuComponent;
