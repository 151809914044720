const config = {
  apiKey: "AIzaSyAX5fKzSDCXnR1tmho3tv9khguqFrK-ay4",
  authDomain: "buhope-3a95a.firebaseapp.com",
  projectId: "buhope-3a95a",
  storageBucket: "buhope-3a95a.appspot.com",
  messagingSenderId: "103718161117",
  appId: "1:103718161117:web:f5c04aed05a3d404eb2934"
}

export default config;
