// react,
import React, { useState } from 'react';
// application
import { Card, Row, Col, Button, Checkbox, Image, Tag } from 'antd';
// import url from '~/services/url';
import { AccesoriesGoPRo } from '../../../constants/constants'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';

const gridStyle = {
  maxWidth: '30%',
  textAlign: 'center',
  border: '1px solid #ee791f',
  // margin: '14px',
};
const { Meta } = Card;
function ContentAllProducts({ openModal = () => {}, onChange = () => {}, products = [], optionSize = null, positionShop = '', menuList }) {
  // const openModal = (el: any) => {
  //   console.log('open modal', el)
  // }
  console.log(products)
  const getNameCategoryByCode = (cod) => {
    let result = ""
    if (menuList && cod) {
      menuList.forEach(e => {
        if (e && e.code === cod) result = e.label
      });
    }
    return result;
  }
    return (
        <React.Fragment>
          <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
          {
                          (products && products.length > 0) ? products.map(el => {
                            return (
                              <Col>
                                 <Card
                                    hoverable
                                    id="card-product"
                                    style={{ width: 300 }}
                                    cover={
                                      // <img
                                      //   alt="example"
                                      //   src={(el && el.img) && el.img}
                                      //   className="img-responsive"
                                      // />
                                      <Image
                                        width={200}
                                        src={(el && el.img) && el.img}
                                        className={(positionShop && positionShop === '02') ? 'blackramps-active img-responsive' : "normal img-responsive"}
                                        placeholder="Cargando..."
                                      />
                                    }
                                    >
                                      {(el && el.category) && 
                                      <div className="row" style={{margin: "0 0 10px", textAlign: "left"}}>
                                      <Tag id="tag-cat" color="cyan">{el.category && getNameCategoryByCode(el.category)}</Tag>
                                      </div>
                                     }
                                    <Meta
                                      title={(el.name) && el.name.replace("_", " ").replace("_", " ").replace("_", " ").replace("_", " ").replace("_", " ")}
                                      description={`Precio de...  S/ ${el.price}`}
                                    />
                                    <br></br>
                                    <Checkbox onChange={onChange} value={el} checked={el.checked} disabled={(el.stock === el.soldUnits)} >Agregar a la lista</Checkbox>
                                    <br></br>
                                    <br></br>
                                    <Button
                                    disabled={(el.stock === el.soldUnits)}
                                    type="primary" size="large" onClick={() => openModal(el)}>{(el.stock === el.soldUnits) ? "AGOTADO" : "PEDIR"}</Button>
                                </Card>
                              </Col>                             
                              )
                          })
                          :
                          <div className="loader">
                            <div className="load">
                              <div className="circle" id="circle-1" ></div>
                              <div className="circle" id="circle-2" ></div>
                              <div className="circle" id="circle-3" ></div>
                            </div>
                          </div>

                        }
          </Row>                        
        </React.Fragment>
    );
}

export default ContentAllProducts;
