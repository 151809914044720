import React, { useState, useEffect } from 'react';
import { Form, Input, Button, InputNumber, Card , Row, Col, Image, Tag, notification, Select, PageHeader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import TusAccesoriosPeruServices from '../../services/services';
// import { DATA_AUX } from '../../constants/constants';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const { Meta } = Card;
const StockView = ({ propsAux, getAllClientByIdFirebaseDB, userInSession, refDashboardProducts, products = [], showProducts = () => {}, refSaveProducts, refDashboarClients, storage }) => {
  const idACtive = propsAux.match.params.id;
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showAllProducts, setShowAllroducts] = useState(true);
  const [fileList, setFileList] = useState([])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [urlProductAdded, setUrlProductAdded] = useState(null)
  const [listAllClientsBD, setListAllClientsBD] = useState([])
  const [clientIdSelected, setClientIdSelected] = useState(null)
  const [clientSelectedUI, setClientSelectedUI] = useState(null)
  const [categorySelected, setCategorySelected] = useState(null)

  console.log(products)
  let acumTotalAmount = 0;
  let totalAmounts = []
  totalAmounts = products && products.map(el => el.price * el.stock)
  console.log(totalAmounts)
  acumTotalAmount = (totalAmounts && totalAmounts.length) && totalAmounts.reduce((a,b) => a +b )
  console.log(userInSession)
  console.log(idACtive)
if (!userInSession) getAllClientByIdFirebaseDB(idACtive)
  const getAllClientsBDFirebase = () => {
    let allIds = []
    let clientsAux = []
    refDashboarClients.on("value", (snapshot) => {
         if (snapshot.val() !== null) {
          snapshot.forEach(e => {
            allIds.push(e.key)
          })
           clientsAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           clientsAux = Object.values(clientsAux)
           clientsAux = clientsAux.map((e, index) => {
            console.log(allIds)
           if (allIds.length) {
           allIds.forEach(k => {
             e.keyBD = `${allIds[index]}`
           })
           }
           return e
        })
           setListAllClientsBD(clientsAux)
           console.log(clientsAux, "clientsAux")
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
}

  if (listAllClientsBD.length === 0) getAllClientsBDFirebase()

  const goToBack = () => {
    if (showAddProduct) setShowAddProduct(false)
    // showProducts()
    setShowAllroducts(true)
  }

  const addProduct= () => {
    if (showAllProducts) setShowAllroducts(false)
    setShowAddProduct(true)
  }

  const onFinishAddProduct = (values) => {
    console.log(values)
    let aux = {...clientSelectedUI}
    if (values && clientIdSelected && clientSelectedUI) {
      const service = new TusAccesoriosPeruServices(refSaveProducts)
      console.log(clientIdSelected)
      values.soldUnits = 0
      values.img = urlProductAdded || ''
      // values.cod =  1
      // values.cod = products[products.length - 1] && products[products.length - 1].cod + 1
      console.log(products)
      console.log(values)
      if (aux && !aux.products) {
        aux.products = []
      }
      if (categorySelected) {
        values.category = {}
        values.category = categorySelected
      }
      values.cod = (aux.products.length === 0) ? 1 : aux.products.length + 1
      aux.products.push(values)
      if (values && values.client) delete values.client
      service.updateProductForClientDB(clientSelectedUI.keyBD,aux).then(res => {
        openNotification()
        setClientIdSelected("")
        setUrlProductAdded(null)
        setShowAddProduct(false)
        // if (formRef && formRef.current) {
        //   console.log("AQUI")
        //   onReset()
        //   formRef.current.setFieldsValue({
        //     name: '',
        //   })
        // }
      })
    }
  }
  // const onReset = () => {
  //   formRef.current.resetFields();
  // };
  const openNotification = () => {
    notification.success({
      message: `PRODUCTO REGISTRADO`,
      description:
        'El producto se registro con éxito.',
    });
  };

  const onFinishFailedAddProduct = (errorInfo) =>{

  }
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(reader.result)
        resolve(reader.result);
      }
      reader.onerror = error => reject(error);
    });
  }
  // const handleCancel = () => setPreviewVisible(false)
  const handleChange = (e) => {
    console.log(e.fileList)
    if (e && e.target) {
      const reader = new FileReader();
      let file = e.target.files[0]; // get the supplied file
      // if there is a file, set image to that file
      if (file) {
        console.log(file)
        uploadImage(file)
      }
    }
  }
  console.log(urlProductAdded)
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    console.log("fileList", fileList)
    // this.setState({
    //   previewImage: file.url || file.preview,
    //   previewVisible: true,
    //   previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    // });
  };
  const uploadImage = (image) => {
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      error => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(image.name)
          .getDownloadURL()
          .then(url => {
           console.log(url)
           setUrlProductAdded(url)
          });
      }
    );

  }
  // let aux_result = []
  // let allIds = []
  // console.log(Object.values(DATA_AUX) && Object.values(DATA_AUX))
  // aux_result = Object.values(DATA_AUX) && Object.values(DATA_AUX)
  // aux_result = Object.values(aux_result[0])
  // aux_result = aux_result.map((e, index) => {
  //   e.id = index + 1
  //   return e
  // })
  // console.log(aux_result)
  const handleClient = (obj) => {
    let objSelected = {}
    console.log(obj)
    setClientIdSelected(obj)
    if (listAllClientsBD) {
      listAllClientsBD.forEach(e => {
        if (e.keyBD === obj) setClientSelectedUI(e)
      })
    }
    console.log(objSelected)
  }
  const handleCategory = (id) => {
    let objSelected = ""
    console.log(id)
    setClientIdSelected(id)
    if (listAllClientsBD) {
      listAllClientsBD.forEach(e => {
        if (e.keyBD === id) setCategorySelected(e)
      })
    }
    console.log(objSelected)  }
  return (
    <React.Fragment>

    <PageHeader
      className="site-page-header-responsive"
      title={`Hola, ${(userInSession) ? userInSession.name : ""}!`}
      style={{paddingLeft: 0}}
      extra={[
        // <Tag color="#f50"># de productos</Tag>,
       <Tag color="#87d068">CANTIDAD DE PRODUCTOS <br /><b>{acumTotalAmount && `S/ ${acumTotalAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b></Tag>,
        // <Button key="3">Operation</Button>,
        // <Button key="1" type="primary">
        //   Primary
        // </Button>,
      ]}>
      </PageHeader>

    <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
    {
        (showAddProduct) &&
      <Col className="gutter-row row-stock" style={{height: "40px !important"}}>
            <Button type="primary" onClick={() => goToBack()} size="large">{"IR ATRAS"}</Button>
      </Col>
    }
      {
        (!showAddProduct) &&
        <Col className="gutter-row" style={{height: "40px !important"}}>
            <Button type="primary" onClick={() => addProduct()} size="large">+ PRODUCTO</Button>
      </Col>
      }
    </Row>
    {(showAllProducts || showAddProduct) && <hr id="hr-stock" />}

    {showAddProduct && <h2>Te encuentras agregando un producto nuevo</h2>}
    {
      showAddProduct &&
      <div>
        {/* <button onClick={() => uploadImage()}>
          Cargar foto
        </button> */}
        <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinishAddProduct}
      onFinishFailed={onFinishFailedAddProduct}
    >
        <Form.Item
          label="Selecciona un cliente"
          name="client"
        >
        <Select defaultValue={clientIdSelected ? clientIdSelected : null} style={{ width: 120 }} onChange={(e) => handleClient(e)} >
          {
            listAllClientsBD && listAllClientsBD.map(e => {
              return <Option value={e.keyBD}>{e.name}</Option>
            })
          }
        </Select>
        </Form.Item>
        {
          (clientIdSelected && clientSelectedUI && clientSelectedUI.configuration && clientSelectedUI.configuration.productsCategories) &&
          <Form.Item
          label="Selecciona una categoría"
          name="category"
        >
        <Select defaultValue={categorySelected ? categorySelected : null} style={{ width: 120 }} onChange={(e) => handleCategory(e)} >
          {
            clientSelectedUI.configuration.productsCategories.map(e => {
              return <Option value={e.code}>{e.label}</Option>
            })
          }
        </Select>
        </Form.Item>
        }
        <Form.Item
          label="Nombre del producto"
          name="name"
        >
          <Input style={{ width: 320 }} />
        </Form.Item>
        <Form.Item>
        <input type="file" className="input-product-img" onChange={handleChange} accept="image/x-png,image/jpeg" />
          {
            urlProductAdded &&
            <img src={urlProductAdded} className="product-loaded"/>
          }
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="desc"
        >
          <Input style={{ width: 320 }} />
        </Form.Item>
        <Form.Item
          label="Precio"
          name="price"
        >
        <InputNumber min={1} value="" onPressEnter={() => null} />
        </Form.Item>
        <Form.Item
          label="Stock"
          name="stock"
        >
          <InputNumber min={1} value="" onPressEnter={() => null} />
        </Form.Item>
        <Button type="primary" htmlType="submit" disabled={!urlProductAdded}>
          AGREGAR PRODUCTO
        </Button>
      </Form>
      </div>
    }
    {showAllProducts && <h2>Estas viendo el total de stock</h2>}

    <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }} id="row-all-stock">
    {
      (showAllProducts && userInSession && userInSession.products) && userInSession.products.map(el => {
        return (
          <Col>
          <Card
              hoverable
              id="card-product-stock"
              className={`${(el.stock - el.soldUnits) <= 0 && 'out-stock'} card-all-stock`}
              style={{ width: 180 }}
              cover={
                <Image
                  width={180}
                  src={(el && el.img) && el.img}
                  className="normal img-responsive"
                  placeholder="Cargando..."
                />}
              >
                <Meta
              title={(el.name) && el.name.replace("_", " ").replace("_", " ").replace("_", " ").replace("_", " ").replace("_", " ")}
              description={`CANTIDAD... ${el.stock} \b PRECIO... ${el.price}`}
            />
              <br></br>
              <table id="table-stock">
          <thead>
            <tr>
              <td>VENDIDOS</td>
              <td>STOCK</td>
            </tr>
          </thead>
            <tbody>
              <tr>
                <td>{el.soldUnits}</td>
                <td>{(el.stock - el.soldUnits) <= 0 ? 'AGOTADO' : el.stock - el.soldUnits}</td>
              </tr>
              </tbody>
              </table>
              <br></br>
              <Button type="primary" size="large">AÑADIR</Button>
          </Card>
          </Col>
        )
      })
    }
    </Row>
  </React.Fragment>
  )
}

export default StockView;
